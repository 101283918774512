<!--
 * @Description: 停车场运营管理系统 车辆管理 黑白名单 白名单车辆 whiteListCar
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-02 09:32:48
 * @LastEditors: zhoucheng
-->
<template>
  <div class="mainBody">
    <!--上半部查询-->
    <el-row class="topSelectTwoLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label='车牌号'
                          label-width="90px">
              <el-input placeholder="请输入"
                        v-model="searchForm.plateNumber">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="停车场">
              <el-select clearable
                         v-model="searchForm.parkId"
                         placeholder="全部">
                <el-option v-for="(item, index) in parkNameList"
                           :key="index"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

        </el-row>
        <el-row class="topButton">
          <el-button type="primary"
                     @click="onSubmit">查 询</el-button>

          <el-button type="info"
                     @click="resetForm"
                     style="margin-left:17px;">重 置</el-button>
        </el-row>
      </el-form>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>白名单车辆管理</span>
        <span class="tableTitleButton topButton">
          <el-button type="success"
                    v-show="insert_user"
                     @click="addTemporaryVehicles">新 增</el-button>
          <el-button type="danger"
                     style="margin-left:17px"
                     v-show="mass_deletion"
                     @click="massDeletion">批量删除</el-button>
        </span>
      </el-row>
      <el-row class="tableContent">
        <!-- 表格 -->
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  ref="tableRef"
                  :tableConfig='tableConfig'
                  @getList="getList"
                  :paginationConfig='paginationConfig'
                  :tableFun="tableFun">
          <template slot="isFirst">
            <el-table-column type="selection"></el-table-column>
          </template>
          <template slot="operating">
            <el-table-column label="状态"
                             prop='blackWhiteListStatus'>
              <template slot-scope="scope">
                <span :class="blackWhiteListStatus(scope.row.blackWhiteListStatus)">
                  {{scope.row.blackWhiteListStatus == '1'?'生效中':'已失效'}}</span>
              </template>
            </el-table-column>
          </template>
          <template slot="operating">
            <el-table-column label="操作"
                             class-name='class-name'>
              <template slot-scope="scope">
                <el-row>
                  <el-col :span="8">
                    <el-button @click="outOfCommission(scope.row)"
                               type="text"
                               size="medium">
                      {{scope.row.blackWhiteListStatus === 1?'停用':'启用'}}
                    </el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button @click="handleOrderUpdate(scope.row)"
                               type="text"
                               v-show="click_update"
                               size="medium">修改</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button type="text"
                               size="medium"
                               v-show="click_delete"
                               @click="handleOrderDelete(scope.row)">删除</el-button>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
          </template>
        </dt-table>
        <!-- 新增、修改弹窗 -->
        <el-dialog :title="this.dialogTitle"
                   :visible.sync="dialogVisible"
                   :close-on-click-modal="false"
                   append-to-body
                   @close="popClose">
          <el-form label-width="150px"
                   :model="addForm"
                   ref="addForm"
                   :rules="rules"
                   :inline="true"
                   size="small">
            <el-row justify="space-around">
              <el-col :span="12">
                <el-form-item label="停车场名称"
                              prop="parkId">
                  <el-select clearable
                             v-model="addForm.parkId"
                             placeholder="请选择"
                             class="dt-form-width">
                    <el-option v-for="(item, index) in parkNameList"
                               :key="index"
                               :label="item.name"
                               :value="item.code"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="车牌号"
                              prop="plateNumber">
                  <el-input v-model="addForm.plateNumber"
                            class="dt-form-width"
                            placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row justify="space-around">
              <el-col :span="12">
                <el-form-item label="车牌颜色"
                              prop="numberPlateColorCode">
                  <el-select clearable
                             v-model="addForm.numberPlateColorCode"
                             placeholder="请选择"
                             class="dt-form-width">
                    <el-option v-for="(item, index) in colorList"
                               :key="index"
                               :label="item.name"
                               :value="item.code"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row justify="space-around">
              <el-col :span="12">
                <el-form-item label="生效时间"
                              prop="effectiveTime">
                  <el-date-picker v-model="addForm.effectiveTime"
                                  style="width:230px"
                                  type="datetime"
                                  value-format="yyyy-MM-dd HH:mm:ss"
                                  placeholder="选择日期时间">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="失效时间"
                              prop="expirationTime">
                  <el-date-picker v-model="addForm.expirationTime"
                                  style="width:230px"
                                  type="datetime"
                                  value-format="yyyy-MM-dd HH:mm:ss"
                                  placeholder="选择日期时间">
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row justify="space-around">
              <el-col :span="12">
                <el-form-item label="车主姓名"
                              prop="carOwnerName">
                  <el-input v-model="addForm.carOwnerName"
                            class="dt-form-width"
                            placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="车主手机号"
                              prop="phoneNumber">
                  <el-input v-model="addForm.phoneNumber"
                            class="dt-form-width"
                            placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row justify="space-around">
              <el-col :span="12">
                <el-form-item label="车主联系地址"
                              prop="carOwnerContactAddress">
                  <el-input v-model="addForm.carOwnerContactAddress"
                            class="dt-form-width"
                            placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="备注"
                              prop="remark">
                  <el-input v-model="addForm.remark"
                            class="dt-form-width"
                            placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <el-row type="flex"
                  justify="end">
            <el-col :span="12"
                    class="dt-button-box">
              <el-button type="primary"
                         @click="handleClose('addForm')">确 定</el-button>
              <el-button @click="dialogVisible = false"
                         type="info">取 消</el-button>
            </el-col>
          </el-row>
        </el-dialog>
      </el-row>
    </el-row>
  </div>
</template>
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from '@/components/table.vue'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
  },
  data () {
    //这里存放数据
    return {
      tableFun: { 'selection-change': this.handleChange, },
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      tableConfig: { border: true },
      listMap: {
        pageNum: 1,
        pageSize: 15
      },
      tableColumnList: [
        {
          prop: 'parkName',
          label: '停车场名称',
        },
        {
          prop: 'plateNumber',
          label: '车牌号',
        },
        {
          prop: 'numberPlateColor',
          label: '车牌颜色',
        },
        {
          prop: 'effectiveTime',
          label: '生效时间',
        },
        {
          prop: 'expirationTime',
          label: '失效时间',
        },
        {
          prop: 'remark',
          label: '备注',
        },
      ],
      tableList: {
        list: [],
      },
      searchForm: {
        plateNumber: '',//车牌号
      },
      pageNum: 1,
      pageSize: 15,
      //停车场名称
      parkNameList: [],
      //新增表单
      addForm: {
        plateNumber: '', //车牌号
        carOwnerName: '', //车主姓名
        phoneNumber: '', //手机号
        //idCardNumber: '', //身份证信息
        carOwnerContactAddress: '', //车主联系地址
        numberPlateColorCode: '', //车牌颜色编码
        remark: '', //备注
        creater: '', //创建人
        createTime: '', //加入时间
        lastModifyTime: '', //最后修改时间
      },
      colorList: [],//车牌颜色下拉框
      dialogTitle: '',//弹出框标题
      dialogVisible: false,//弹出框显示
      massDeleteList: [],//多选框列表
      rules: {
        parkId: [{ required: true, message: '请选择停车场名称', trigger: 'change' }],
        plateNumber: [
          { required: true, message: '请输入车牌号', trigger: 'blur' },
          { min: 3, max: 10, message: '长度在 3 到 10 个字符', trigger: 'blur' }
        ],
        numberPlateColorCode: [{ required: true, message: '请选择车牌颜色', trigger: 'change' }],
        effectiveTime: [{ required: true, message: '请选择生效时间', trigger: 'change' }],
        expirationTime: [{ required: true, message: '请选择失效时间', trigger: 'change' }],
        //手机号校验
        phoneNumber: [{ pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/, message: '请输入正确的手机号', trigger: 'blur' }],
      },
      timeList: [],//选择时间数组
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    //获取菜单按钮权限
    this.buttonList = localStorage.getItem('buttonListByUser')
    this.buttonShow();

    this.queryTableList()
    this.getQueryDict()
    this.getColorDict()
  },
  //方法集合
  methods: {
     //菜单按钮展示权限
     buttonShow () {
      const buttonMenuName = JSON.parse(this.buttonList)
      const menuNames = [];
      for (let i = 0; i < Object.keys(buttonMenuName).length; i++) {
        const menuName = buttonMenuName[i].menuName;
        menuNames.push(menuName);
        if (menuNames[i] === '新增') {
          this.insert_user = true
        }
        else if (menuNames[i] === '修改') {
          this.click_update = true
        }
        else if (menuNames[i] === '删除') {
          this.click_delete = true
        }
        else if (menuNames[i] === '批量删除') {
          this.mass_deletion = true
        }
      }
    },
    //初始化数据
    queryTableList () {
      this.searchForm['pageNum'] = this.pageNum;
      this.searchForm['pageSize'] = this.pageSize;
      this.$whiteListCar.queryListByPage(this.searchForm).then(response => {
        this.tableList.list = response.resultEntity.list
        this.paginationConfig.total = response.resultEntity.total
      })
    },
    //分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.queryTableList()
    },
    //查询
    onSubmit () {
      this.listMap.pageNum = 1
      this.pageNum = 1
      this.queryTableList()
    },
    //重置表单
    resetForm () {
      this.searchForm = {}
      this.listMap.pageNum = 1
      this.pageNum = 1
      this.queryTableList()
    },
    //新增列表
    add () {
      if (this.addForm.effectiveTime > this.addForm.expirationTime) {
        this.$message({
          message: '失效时间应大于生效时间',
          type: 'error'
        });
      }
      else {
        this.addForm['listType'] = "0"
        this.$whiteListCar.blackWhiteInsert(this.addForm).then((response) => {
          if (response.resultCode === '2000') {
            this.$message({
              message: '新增成功',
              type: 'success'
            });
          }
          this.queryTableList()
        })
      }
    },
    //修改列表
    update () {
      if (this.timeList.length) {
        this.addForm.effectiveTime = this.timeList[0]
        this.addForm.expirationTime = this.timeList[1]
      } else {
        this.addForm.effectiveTime = ''
        this.addForm.expirationTime = ''
      }
      this.$whiteListCar.blackWhiteUpdate(this.addForm).then((response) => {
        if (response.resultCode === '2000') {
          this.$message({
            message: '修改成功',
            type: 'success'
          });
        }
        this.queryTableList()
      })
    },
    // 删除记录
    handleOrderDelete (row) {
      this.$confirm('确认删除该条记录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let info = [{
          listId: row.listId,
          parkId: row.parkId
        }]
        this.$whiteListCar.blackWhiteDelete(info).then(response => {
          if (response.resultCode === '2000') {
            this.$message({
              message: '删除成功',
              type: 'success'
            });
          }
          this.queryTableList()
        })
      })
    },
    //批量删除
    massDeletion () {
      if (this.massDeleteList.length === 0) {
        this.$message({ showClose: true, message: '至少勾选一条记录', type: 'error' });
      } else {
        this.$confirm('确认批量删除白名单车辆?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.$whiteListCar.blackWhiteDelete(this.massDeleteList).then(response => {
            if (response.resultCode === '2000') {
              this.$message({ message: '批量删除成功', type: 'success' });
            }
            this.queryTableList()
          })
        })
      }
    },
    //停用记录
    outOfCommission (row) {
      let info = {
        listId: row.listId,
        parkId: row.parkId
      }
      if (row.blackWhiteListStatus === 1) {
        this.$whiteListCar.disable(info).then(response => {
          if (response.resultCode === '2000') {
            this.$message({ message: '停用成功', type: 'success' });
            this.queryTableList()
          }
        })
      } else if (row.blackWhiteListStatus === 2) {
        // 启用
        this.$whiteListCar.enable(info).then((response) => {
          if (response.resultCode === '2000') {
            this.$message({
              message: '启用成功',
              type: 'success'
            });
            this.queryTableList()
          }
        })
      }
    },
    //新增停车场按钮
    addTemporaryVehicles () {
      this.addForm = {}
      this.dialogTitle = '新增白名单'
      this.dialogVisible = true
      this.dialogType = 0
      this.timeList = []
    },
    // 修改记录
    handleOrderUpdate (row) {
      this.dialogType = 1
      this.dialogTitle = '白名单修改'
      this.dialogVisible = true
      this.addForm = this.$deepClone(row)
      this.timeList = [row.effectiveTime, row.expirationTime]
    },
    //弹出框确认按钮
    handleClose (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.dialogType === 0) {
            this.add()
          } else if (this.dialogType === 1) {
            this.update()
          }
          this.dialogVisible = false
        } else {
          return false;
        }
      });
    },
    //表格多选按钮
    handleChange (val) {
      val.forEach(item => {
        this.massDeleteList.push({
          listId: item.listId,
          parkId: item.parkId
        })
      })
    },
    //状态颜色变化
    blackWhiteListStatus (param) {
      let res = "";
      if (param == '1') {
        res = "effective";
      } else if (param == '2') {
        res = "effectiveness";
      }
      return res;
    },
    // 清空验证
    popClose () {
      this.$refs.addForm.resetFields();
    },
    //获取停车场名称
    getQueryDict () {
      let info = {
        columnName: ["park_id", "park_name"],
        tableName: "tb_park",
        whereStr: [
          {
            colName: "park_type_code",
            value: '2' //这个是停车场类型 1是路内2是路外
          }
        ],
      }
      this.$queryDict.queryDictWithAuth(info).then((response) => {
        this.parkNameList = response.resultEntity
      })
    },
    //获取车牌颜色
    getColorDict () {
      let info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: '204E657017184ADFAE6E39629B38DDC2',
          },
        ]
      }
      this.$queryDict.queryDict(info).then((response) => {
        let data = response.resultEntity
        this.colorList = data
      })
    },
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 135px;
.mainBody {
  height: 100%;
  .topSelectTwoLine {
    @include background('blockBg');
    width: 100%;
    height: $topSelectHeight;
    padding-top: 24.3px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      padding-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    @include background('blockBg');
    width: 100%;
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .topButton {
        display: flex;
        padding-left: 95.5px;
      }
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
.dt-button-box {
  display: flex;
  justify-content: flex-end;
}
//生效字颜色
.effective {
  color: #bae8ff;
}
//失效字颜色
.effectiveness {
  color: #f69624;
}
.dt-form-width {
  width: 230px;
}
</style>
